@import '~antd/dist/antd.less';

.fl-right {
        float: right;
}
.text-small {
	font-size: 85%;
}
.text-right {
	text-align: right;
}
.text-center {
	text-align: center;
}

.d-inline-block {
        display: inline-block;
}
.d-none {
        display: none !important;
}
.d-block {
        display: block !important;
}

.z-10 {
        z-index: 10;
}
.mt-0 {margin-top:0!important;}
.mb-0 {margin-bottom:0!important;}
.mt-5 {
        margin-top: 5px!important;
}
.mt-10 {
        margin-top: 10px!important;
}
.mt-15 {
        margin-top: 15px!important;
}
.mt-30 {
        margin-top: 30px!important;
}
.mb-10 {
        margin-bottom: 10px!important;
}
.mb-15 {
        margin-bottom: 15px!important;
}
.mb-20 {
        margin-bottom: 20px!important;
}
.mb-25 {
        margin-bottom: 25px!important;
}
.mb-30 {
        margin-bottom: 30px!important;
}
.ml-10 {
	margin-left: 10px!important;
}
.ml-15 {
	margin-left: 15px!important;
}
.ml-20 {
	margin-left: 20px!important;
}
.ml-30 {
	margin-left: 30px!important;
}

.take-all {
        width: 100%;
}

.ant-form-item-with-help .ant-form-item-explain {
	margin-bottom: 18px;
}

@media screen and (min-width: 576px) {
        .d-sm-none {
                display: none !important;
        }
        .d-sm-inline {
                display: inline !important;
        }
        .d-sm-inline-block {
                display: inline-block !important;
        }
        .d-sm-block {
                display: block !important;
        }
}

/* Full Background Image */
img.full-bg {
    min-height: 100%;
    min-width: 1280px;
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
}

@media screen and (max-width: 1280px) {
    img.full-bg {
        left: 50%;
        margin-left: -640px;
    }
}

.animation-pulseSlow {
    animation-name: pulseSlow;
    -webkit-animation-name: pulseSlow;
    animation-duration: 30s;
    -webkit-animation-duration: 30s;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-timing-function: linear;
}

@keyframes pulseSlow {
    0% {
        transform: scale(1.1);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.1);
    }
}

@-webkit-keyframes pulseSlow {
    0% {
        -webkit-transform: scale(1.1);
    }
    50% {
        -webkit-transform: scale(1);
    }
    100% {
        -webkit-transform: scale(1.1);
    }
}

.page-form-container {
        max-width: 320px;
        margin: 90px auto 90px;
        padding: 10px 20px;
        background-color: #fff;

        h2 {
                text-align: center;
        }
}
.padded-container {
	padding: 20px 0 40px;
}

.simple-layout {
        h2 {
                margin: -10px -20px 30px;
                padding: 10px 20px;
                background: @primary-color;
                text-align: center;
                color: #ffffff;
        }
}

.ant-layout-header.app-header {
	/*background-color: #D8DCE1;*/
	background-color: rgba(194,18,18, 0.90);
	padding: 0 15px;
}

.ant-menu-dark {
	color: white;
	.ant-btn-link {
		color: white;
	}
}
.ant-layout-sider-trigger {
	background: @layout-header-background;
}

.ant-layout .ant-layout-footer {
        text-align: center;
        /*background: transparent !important;*/
        background: #001529;
        color: #fff !important;
        white-space: nowrap;
        font-size: 12px;
}

.sider-menu-logo {
	img {
		max-width: 100%;
	}
	padding: 0px 10px;
	margin-bottom: 25px;
}

main.ant-layout-content {
	padding: 10px 15px;
}
.user-info .user-name {
	margin-left: 10px;
}
.ant-layout-content {
       	.ant-breadcrumb {
		margin-bottom: 10px;
	}

	.main-panel {
		padding: 20px;
		background-color: #ffffff;
		min-height: 75%;
	}
}

.floating-plus-button {
	position: absolute;
	top: -15px;
	right: -15px;
	z-index: 10;
}
.dynamic-delete-button {
	margin-left: 5px;
}
.pl-20 {
	padding-left: 20px;
}
.pl-30 {
	padding-left: 30px;
}
.subscription-status-block {
	width: 100%;
	height: 1.8em;
}
.subscription-status-expired {
	background: red;
	color: white;
}
.subscription-status-inactive {
	background: black;
	color: white;
}
@media screen and (min-width: 992px) {
	.zic-card {
		max-width: 320px;
		
	}
}
.card-footer-unpadded {
	margin: 0 -24px -24px;
}

.zic-card {
	.qrcode-svg {
        	max-height: 320px;
	}

	.card-footer-unpadded {
		padding: 10px;
		background: #2E2E2E;
		text-align: center;

		img {
			max-width: 256px;
		}
	}

	.ant-card-meta {
		margin-top: -15px;
	}
}
#user-edit .qrcode-svg {
        max-width: 250px;
        max-height: 250px;
}

@primary-color: #A00000;@layout-header-background: #2E2E2E;